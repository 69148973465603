import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { isEmpty } from "lodash";
import numeral from "numeral";
import NotyfContext from "../../../contexts/NotyfContext";
import LoadingState from "../../../components/common/LoadingState";

// Components
import SourceOfFinancial from "./components/SourceOfFinancial";
import FinancialRequirement from "./components/tables/FinancialRequirement";
import Purpose from "./components/Purpose";
import Background from "./components/Background";
import ProjectComponents from "./components/ProjectComponents";
import Justification from "./components/Justification";
import ViewPaperWork from "./components/pdf-components/ViewPaperWork";
import MinistryComments from "./components/MinistryComments";
import MOFComments from "./components/MOFComments";
import UPENComments from "./components/UPENComments";
import Closing from "./components/Closing";
import ConfirmApprove from "./components/ConfirmApprove";
import ConfirmCancel from "./components/ConfirmCancel";

// Redux
import {
  approveVirementApplication,
  cancelVirementApplication,
  getVirementApplication,
  updateVirementApplication,
} from "../../../redux/slices/jppn/jppnCeilingVirementSlice";
import {
  getProjectNameChangesApplication,
  approveEditNameApplication,
} from "../../../redux/slices/jppn/jppnProjectNameChanges";
import {
  approveEditScopesApplication,
  cancelProjectScopesApplication,
  getProjectScopesChangesApplication,
} from "../../../redux/slices/jppn/jppnProjectScopesChanges";
import {
  approveLocationChangesApplication,
  getLocationChangesApplication,
} from "../../../redux/slices/jppn/jppnLocationChangesSlice";
// import { getNewProjectApplication } from "../../../redux/slices/jppn/jppnNewProjectListingSlice";

const MainPage = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  const { category, id } = routeParams;

  const [ceilingInput, setCeilingInput] = useState("");
  const [purpose, setPurpose] = useState("");
  const [showConfirmApprove, setShowConfirmApprove] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [cancelReasonValue, setCancelReasonValue] = useState("");

  const { currentRmk, application, draft } = useSelector((state) => ({
    currentRmk: state.rmkReducer.activeRmk,

    draft: state.jppnReducer.virement.draft,
    application: state.jppnReducer.application,
  }));

  const handleViewPaperWork = () => {
    navigate(`/permohonan/jppn/${category}/${id}/semak`);
  };

  const handleConfirmApprove = () => {
    setShowConfirmApprove(true);
  };

  const handleApprove = async () => {
    if (category === "kenaikan-siling") {
      await dispatch(approveVirementApplication(id));
    }
    if (category === "perubahan-nama-projek") {
      await dispatch(approveEditNameApplication(id));
    }
    if (category === "perubahan-skop") {
      await dispatch(approveEditScopesApplication(id));
    }
    if (category === "ubah-lokasi") {
      await dispatch(approveLocationChangesApplication(id));
    }

    handleNotification("approved");
    navigate(`/perakuan/jppn`);
  };

  const handleCancel = async () => {
    const newValues = {
      id: id,
      canceled_reason: cancelReasonValue,
    };

    if (category === "kenaikan-siling") {
      await dispatch(cancelVirementApplication(newValues));
    }
    if (category === "perubahan-skop") {
      await dispatch(cancelProjectScopesApplication(newValues));
    }

    handleNotification("canceled");
    navigate(`/perakuan/jppn`);
  };

  // Notification
  const handleNotification = (type) => {
    let color = "success";
    let msg = "Permohonan telah diluluskan";

    if (type === "canceled") {
      color = "error";
      msg = "Permohonan telah dibatalkan";
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(async () => {
    let source = axios.CancelToken.source();

    const { id } = routeParams;

    const props = {
      source: source,
      id: id,
    };

    if (routeParams.category === "kenaikan-siling") {
      await dispatch(getVirementApplication(props));
    }
    if (routeParams.category === "perubahan-nama-projek") {
      await dispatch(getProjectNameChangesApplication(props));
    }
    if (routeParams.category === "perubahan-skop") {
      await dispatch(getProjectScopesChangesApplication(props));
    }
    if (routeParams.category === "ubah-lokasi") {
      await dispatch(getLocationChangesApplication(props));
    }
    if (routeParams.category === "penyenaraian-projek-baharu") {
      // await dispatch(getNewProjectApplication(props));
    }

    return () => {
      source.cancel();
    };
  }, [dispatch, routeParams]);

  if (isEmpty(application)) return <LoadingState />;

  return (
    <React.Fragment>
      <ConfirmApprove
        show={showConfirmApprove}
        onHide={() => setShowConfirmApprove(false)}
        approve={handleApprove}
      />
      <ConfirmCancel
        show={showConfirmCancel}
        onHide={() => setShowConfirmCancel(false)}
        cancel={handleCancel}
        value={cancelReasonValue}
        handleChange={(e) => {
          setCancelReasonValue(e.target.value);
        }}
      />

      <Container fluid className='p-0'>
        <Card>
          <Card.Header className='d-flex justify-content-center pb-2'>
            <Card.Title className='fs-4 w-50 text-center text-uppercase'>
              Kerajaan Negeri Sabah Jawatan Kuasa Perancang Pembangunan Negeri
            </Card.Title>
          </Card.Header>
          <Card.Header className='d-flex justify-content-center pb-3 pt-0'>
            {routeParams.category === "penyenaraian-projek-baharu" && (
              <Card.Title className='fs-4 w-50 text-center'>
                <span className='text-uppercase me-1'>
                  Permohonan Penyenaraian Projek Baharu
                </span>
                <span className='me-1 text-uppercase'>{currentRmk.name}</span>
                <span className='me-1 text-truncate'>
                  ({currentRmk.short_form})
                </span>
              </Card.Title>
            )}
            {routeParams.category === "kenaikan-siling" && (
              <Card.Title className='fs-4 w-50 text-center'>
                <span className='text-uppercase me-1'>
                  Permohonan Menambah Siling Peruntukan Dibawah
                </span>
                <span className='me-1 text-uppercase'>{currentRmk.name}</span>
                <span className='me-1 text-truncate'>
                  ({currentRmk.short_form})
                </span>
              </Card.Title>
            )}
          </Card.Header>
          <Card.Body className='px-5'>
            <div style={{ fontSize: 14 }}>
              <Purpose />
              <Background />
              <ProjectComponents />
              {routeParams.category === "kenaikan-siling" && (
                <FinancialRequirement />
              )}

              <Justification />
              {routeParams.category === "kenaikan-siling" && (
                <SourceOfFinancial />
              )}

              <MinistryComments />

              {routeParams.category === "kenaikan-siling" && <MOFComments />}

              <UPENComments />
              <Closing />
            </div>
          </Card.Body>
        </Card>
        <div className='d-flex justify-content-end gap-3'>
          {!application?.approved_at && (
            <Button
              className='px-3'
              variant='success'
              onClick={handleConfirmApprove}
            >
              Lulus
            </Button>
          )}
          {routeParams.type === "kenaikan-siling" ||
            (routeParams.type === "perubahan-skop" && (
              <>
                {application?.approved_at && (
                  <Button
                    className='px-3'
                    variant='danger'
                    onClick={() => {
                      setShowConfirmCancel(true);
                    }}
                  >
                    Batal
                  </Button>
                )}
              </>
            ))}
        </div>
      </Container>
      {/* <ViewPaperWork /> */}
    </React.Fragment>
  );
};

export default MainPage;
