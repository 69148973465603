import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import NotyfContext from "../../../contexts/NotyfContext";
import LoadingState from "../../../components/common/LoadingState";
import SuccessResetPassword from "../../admin/users/components/SuccessResetPassword";
import usePalette from "../../../hooks/usePalette";

// redux
import { fetchGroupRoles } from "../../../redux/slices/maintenance/rolesSlice";
import {
  getDistrictItems,
  getMinistries,
} from "../../../redux/slices/utilsSlice";
import {
  createUser,
  getUser,
  updateUser,
} from "../../../redux/slices/admin-micro-project/userSlice";
import { resetUserForm } from "../../../redux/slices/admin-micro-project/usersSlice";
import { resetPassword, checkNric } from "../../../redux/slices/usersSlice";

const MainPage = () => {
  const dispatch = useDispatch();
  const palette = usePalette();
  const routeParams = useParams();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  const { type, id } = routeParams;

  const [roleOptions, setRoleOptions] = useState([]);
  const [errors, setErrors] = useState(null);

  const [showForm, setShowForm] = useState(false);
  const [data, setData] = useState(null);

  const {
    groupRoles,
    districtOptions,
    ministries,
    user,
    btnLoading,
    isLoading,
  } = useSelector((state) => ({
    user: state.microProjectUserReducer.user,
    btnLoading: state.microProjectUserReducer.btnLoading,
    isLoading: state.microProjectUserReducer.isLoading,
    groupRoles: state.roleReducer.groupRoles,
    ministries: state.utilsReducer.dropdownItems.ministries,
    districtOptions: state.utilsReducer.dropdownItems.districtItems,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name || "",
      nric: user?.nric || "",
      email: user?.email || "",
      position: user?.profile?.position || "",
      office_phone_number: user?.profile?.office_phone_number || "",
      phone_number: user?.profile?.phone_number || "",
      group_role: !isEmpty(user)
        ? {
            ...user?.group_role,
            label: user?.group_role?.name,
            value: user?.group_role?.name,
          }
        : null,
      input_ministries: user?.user_access_to_ministries || null,
      input_district: user?.user_access_to_district || null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("Masukkan nama penuh")
        .min(5, "Mesti lebih dari 5 perkataan"),
      email: Yup.string()
        .email("Masukkan email yang betul")
        .required("Masukkan email pengguna"),
      // roles: Yup.array().min(1, "Tidak boleh dikosongkan."),
      nric: Yup.string()
        .min(12, "Minimum No.MyKad adalah 12 nombor")
        .max(12, "Maksimum No.MyKad adalah 12 nombor")
        .test(
          "noEOrSign", // type of the validator (should be unique)
          "Sila isi No. MyKad", // error message
          (value) =>
            typeof value === "string" && !/[eE+-]/.test(value.toString()),
        )
        .required("Sila isi No. MyKad"),
    }),
    onSubmit: async (values) => {
      if (type === "kemaskini") {
        const newValues = {
          id: id,
          user_profile_id: user.profile?.id,
          is_deleted: user?.is_deleted,
          name: values.name,
          email: values.email,
          nric: values.nric,
          office_phone_number: values.office_phone_number,
          phone_number: values.phone_number,
          position: values.position,
          group_role: values.group_role?.id || null,
          roles: values.roles,
          ministry_codes: values.input_ministries
            ? values.input_ministries.map((item) => item.code)
            : null,
          district_ids: values.input_district ? values.input_district.id : null,
        };
        await dispatch(updateUser(newValues));
        handleNotification("edited", newValues);
      } else {
        const newValues = {
          name: values.name,
          email: values.email,
          nric: values.nric,
          office_phone_number: values.office_phone_number,
          phone_number: values.phone_number,
          position: values.position,
          group_role: values.group_role?.id || null,
          roles: values.roles,
          ministry_codes: values.input_ministries
            ? values.input_ministries.map((item) => item.code)
            : null,
          district_ids: values.input_district ? values.input_district.id : null,
        };
        await dispatch(createUser(newValues));
        handleNotification("created", newValues);
        navigate("/projek-mikro/admin/senarai-pengguna");
      }
    },
  });

  const handleSaveUser = () => {
    formik.handleSubmit();
  };

  const handleChangeInputMinistries = (value) => {
    formik.setFieldValue("input_ministries", value);
  };

  const handleResetPasswordBypass = async () => {
    const newValues = {
      id: id,
      type: "bypass",
    };
    const response = await dispatch(resetPassword(newValues));
    const data = await response.payload;
    setData(data);
    setShowForm(true);
  };

  const handleResetPassword = async () => {
    const newValues = {
      id: id,
      type: "normal",
    };
    const response = await dispatch(resetPassword(newValues));
    const data = await response.payload;
    handleResetSuccessfullyMsg();
  };

  const handleSelectGroupRole = (v) => {
    const accessTo = v.access_to.split(", ");
    formik.setFieldValue("roles", accessTo);
    formik.setFieldValue("group_role", v);
  };

  const handleCheckNric = async (value) => {
    const values = {
      nric: value,
    };

    const response = await dispatch(checkNric(values));
    const data = await response.payload;

    if (data.status === 500) {
      setErrors({
        ...errors,
        nricExist: data.message,
      });
    } else {
      setErrors(null);
    }
  };

  const handleChangeNric = (value) => {
    formik.setFieldValue("nric", value);

    if (value.length === 12) {
      if (type !== "kemaskini") {
        handleCheckNric(value);
      }
    }
  };

  // Notification
  const handleNotification = (type, values) => {
    let msg = `Maklumat ${values.name}`;
    let color = "success";

    switch (type) {
      case "error":
        color = "error";
        msg = `${msg} berjaya dipadam.`;
        break;
      case "edited":
        msg = `${msg} berjaya dikemaskini.`;
        break;

      default:
        msg = `${msg} berjaya ditambah.`;
        break;
    }

    // Success
    notyf.open({
      type: color,
      message: msg,
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  const handleResetSuccessfullyMsg = () => {
    // Success
    notyf.open({
      type: "success",
      message: "Set Semula password berjaya.",
      duration: 5000,
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "top",
      },
    });
  };

  useEffect(() => {
    let source = axios.CancelToken.source();
    const props = {
      source: source,
      params: {
        is_micro_project_admin: true,
      },
    };

    dispatch(fetchGroupRoles(props.params));
    dispatch(getDistrictItems(props));
    dispatch(getMinistries(props));

    return () => {
      source.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    const options = groupRoles?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.name,
    }));
    setRoleOptions(options);
  }, [groupRoles]);

  useEffect(() => {
    if (type === "kemaskini") {
      dispatch(getUser(id));
    } else {
      dispatch(resetUserForm());
    }
  }, [dispatch, type, id]);

  if (isLoading) return <LoadingState />;

  return (
    <React.Fragment>
      <SuccessResetPassword
        showForm={showForm}
        closeForm={() => setShowForm(false)}
        data={data}
      />

      <Container className='p-0'>
        <Card>
          <Card.Header>Isi Borang</Card.Header>
          <Card.Body>
            <Form className='mb-3' autoComplete='off'>
              <Form.Group className='mb-3'>
                <Form.Label>Nama</Form.Label>
                <Form.Control
                  name='name'
                  value={formik.values?.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.name && formik.errors.name ? true : false
                  }
                  isValid={
                    formik.touched.name && !formik.errors.name ? true : false
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>No. MyKad</Form.Label>
                <Form.Control
                  name='nric'
                  value={formik.values?.nric}
                  onChange={(e) => handleChangeNric(e.target.value)}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    (formik.touched.nric && formik.errors.nric) ||
                    !isEmpty(errors)
                      ? true
                      : false
                  }
                  isValid={
                    formik.touched.nric && !formik.errors.nric ? true : false
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik?.errors?.nric}
                  {errors?.nricExist}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Emel</Form.Label>
                <Form.Control
                  name='email'
                  value={formik.values?.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  isValid={
                    formik.touched.email && !formik.errors.email ? true : false
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Jawatan</Form.Label>
                <Form.Control
                  name='position'
                  value={formik.values?.position}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors?.position}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>No. Telefon</Form.Label>
                <Form.Control
                  name='phone_number'
                  value={formik.values?.phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors?.phone_number}
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>No. Telefon Pejabat</Form.Label>
                <Form.Control
                  name='office_phone_number'
                  value={formik.values?.office_phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors?.office_phone_number}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Peranan Pengguna</Form.Label>
                <Select
                  name='group_role'
                  className='react-select-container'
                  classNamePrefix='react-select'
                  isSearchable
                  options={roleOptions}
                  placeholder='Pilih peranan pengguna...'
                  noOptionsMessage={() => "Tiada Pilihan"}
                  value={formik.values?.group_role}
                  onChange={(value) => handleSelectGroupRole(value)}
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <h5>
                  Akses Kementerian (Tidak perlu di isi jika
                  <span
                    className='mx-1'
                    style={{ fontWeight: 700, color: palette.danger }}
                  >
                    pengguna adalah Pegawai Daerah
                  </span>
                  <span className='me-1' style={{ fontWeight: 700 }}>
                    hanya untuk Pegawai Kementerian sahaja
                  </span>
                  )
                </h5>
                <Select
                  name='input_ministries'
                  className='react-select-container'
                  classNamePrefix='react-select'
                  isSearchable
                  isClearable
                  isMulti
                  closeMenuOnSelect={false}
                  options={ministries}
                  // styles={customStylesForm}
                  placeholder='Pilih Kementerian'
                  value={formik.values.input_ministries}
                  onChange={(value) => handleChangeInputMinistries(value)}
                  noOptionsMessage={() => "Tiada pilihan"}
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>
                  Akses Daerah (Hanya untuk pegawai daerah/Projek Mikro)
                </Form.Label>
                <Select
                  name='input_district'
                  className='react-select-container'
                  classNamePrefix='react-select'
                  isSearchable
                  isClearable
                  options={districtOptions}
                  placeholder='Pilih Daerah'
                  value={formik.values?.input_district}
                  onChange={(value) => {
                    formik.setFieldValue("input_district", value);
                  }}
                  noOptionsMessage={() => "Tiada pilihan"}
                />
              </Form.Group>
            </Form>
            <div className='d-flex justify-content-between gap-3'>
              <div className='d-flex gap-3'>
                <Button
                  variant='light'
                  onClick={handleResetPassword}
                  disabled={btnLoading}
                >
                  Set Semula Kata Laluan
                </Button>
                <Button
                  variant='light'
                  onClick={handleResetPasswordBypass}
                  disabled={btnLoading}
                >
                  Buat Kata Laluan Sementara
                </Button>
              </div>
              <Button
                variant='success'
                onClick={handleSaveUser}
                disabled={btnLoading}
              >
                Simpan
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default MainPage;
