import React, { useEffect, useRef, useState } from "react";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { Button, Form } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import usePalette from "../../../../../hooks/usePalette";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

// Icons
import Icon from "@mdi/react";
import { MdCancel, MdAddCircle } from "react-icons/md";

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Form.Control
    {...field}
    {...props}
    type='text'
    as='textarea'
    style={{ minHeight: 80, maxHeight: 200 }}
  />
);

const Justification = () => {
  const formRef = useRef();
  const palette = usePalette();
  const routeParams = useParams();

  const { values, submitForm } = useFormikContext();

  const [isDisabled, setIsDisabled] = useState(false);

  const { user, project, application } = useSelector((state) => ({
    user: state.userReducer.user,
    project: state.projectReducer.project,
    application: state.jppnReducer.application,
  }));

  useEffect(() => {
    if (isEmpty(application)) return;

    const vireInProject = application?.virement_projects?.find(
      (item) => item.virement_type === "VI",
    );

    const applicationMinistryOwner =
      vireInProject?.project?.ministry_id.toString();
    const userMinistry = user?.user_access_to_ministries?.find(
      (item) => item.code === applicationMinistryOwner,
    )?.code;

    if (applicationMinistryOwner === userMinistry) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [application]);

  return (
    <React.Fragment>
      <Form.Label className='fw-bold text-uppercase'>
        <span className='me-4'>
          {routeParams.category !== "kenaikan-siling" && "4.0"}
          {routeParams.category === "kenaikan-siling" && "5.0"}
        </span>
        Justifikasi
      </Form.Label>
      <FieldArray name='justification'>
        {({ insert, remove, push }) => (
          <React.Fragment>
            {values.justification.map((obj, index) => {
              return (
                <div key={index} className='position-relative'>
                  <div className='d-flex flex-row ps-4'>
                    <div className='me-3'>
                      {routeParams.category !== "kenaikan-siling" && "4."}
                      {routeParams.category === "kenaikan-siling" && "5."}
                      {index + 1}
                    </div>
                    <Form.Group className='position-relative mb-3 w-100'>
                      <Field
                        name={`justification.${index}.description`}
                        component={CustomInputComponent}
                        disabled={isDisabled}
                      />
                      {!isDisabled && (
                        <div
                          className='position-absolute'
                          style={{ top: -11, right: -8 }}
                        >
                          <Tooltip placement='top' title='Padam' arrow>
                            <Link to='#' onClick={() => remove(index)}>
                              <MdCancel size={20} color={palette.danger} />
                            </Link>
                          </Tooltip>
                        </div>
                      )}
                    </Form.Group>
                  </div>

                  {/* Children */}
                  <FieldArray name={`justification[${index}].children`}>
                    {({ insert, remove, push }) => (
                      <>
                        {values.justification[index].children.map(
                          (child, subIndex) => (
                            <div
                              key={subIndex}
                              className='d-flex flex-row ps-4'
                            >
                              <div className='me-5'>
                                5.{index + 1}.{subIndex + 1}
                              </div>
                              <Form.Group className='position-relative mb-3 w-100'>
                                <Field
                                  name={`justification.${index}.children.${subIndex}.description`}
                                  component={CustomInputComponent}
                                />

                                <div
                                  className='position-absolute'
                                  style={{ top: -11, right: -8 }}
                                >
                                  <Tooltip placement='top' title='Padam' arrow>
                                    <Link
                                      to='#'
                                      onClick={() => remove(subIndex)}
                                    >
                                      <MdCancel
                                        size={20}
                                        color={palette.danger}
                                      />
                                    </Link>
                                  </Tooltip>
                                </div>
                              </Form.Group>
                            </div>
                          ),
                        )}
                        <div
                          className='position-absolute'
                          style={{ top: 23, left: 23 }}
                        >
                          <Tooltip placement='top' title='Tambah Pecahan' arrow>
                            <Link
                              to='#'
                              onClick={() => push({ description: "" })}
                            >
                              <MdAddCircle size={20} color={palette.primary} />
                            </Link>
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </div>
              );
            })}
            {!isDisabled && (
              <div className='d-flex justify-content-center'>
                <Button
                  size='sm'
                  variant='secondary'
                  onClick={() => push({ description: "", children: [] })}
                  disabled={isDisabled}
                >
                  + Justifikasi
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  );
};

export default Justification;
