import React, { useEffect, useRef, useState } from "react";
import {
  useFormik,
  FieldArray,
  Form as FormikForm,
  Formik,
  Field,
  ErrorMessage,
  useFormikContext,
} from "formik";
import { Button, Form } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import usePalette from "../../../../../hooks/usePalette";
import { isEmpty } from "lodash";

// Icons
import Icon from "@mdi/react";
import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Form.Control
    {...field}
    {...props}
    type='text'
    as='textarea'
    style={{ minHeight: 60, maxHeight: 85 }}
  />
);

const UPENComments = () => {
  const formRef = useRef();
  const palette = usePalette();
  const routeParams = useParams();

  const { values, submitForm } = useFormikContext();

  const [isDisabled, setIsDisabled] = useState(true);

  const user = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    if (
      user?.user_access_to_executing_agencies.some(
        (item) => item.short_form === "UPEN",
      ) ||
      user?.department?.short_form === "UPEN"
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [user]);

  return (
    <React.Fragment>
      <div className='my-4'>
        <h5 className='fw-bold text-uppercase'>
          <span className='me-4'>
            {routeParams.category !== "kenaikan-siling" && "6.0"}
            {routeParams.category === "kenaikan-siling" && "9.0"}
          </span>
          Komen Unit Perancang Ekonomi Negeri (UPEN) Sabah
        </h5>
        <FieldArray name='upen_comments'>
          {({ insert, remove, push }) => (
            <React.Fragment>
              {values?.upen_comments?.map((item, index) => {
                return (
                  <div key={index} className='d-flex flex-row ps-4'>
                    <div className='me-3'>
                      {routeParams.category !== "kenaikan-siling" && "6"}
                      {routeParams.category === "kenaikan-siling" && "9"}.
                      {index + 1}
                    </div>
                    <Form.Group className='position-relative mb-3 w-100'>
                      <Field
                        name={`upen_comments.${index}.comment`}
                        component={CustomInputComponent}
                        disabled={isDisabled}
                      />

                      <div
                        className='position-absolute'
                        style={{ top: -11, right: -8 }}
                      >
                        <Tooltip placement='top' title='Padam' arrow>
                          <Link to='#' onClick={() => remove(index)}>
                            <MdCancel size={20} color={palette.danger} />
                          </Link>
                        </Tooltip>
                      </div>
                    </Form.Group>
                  </div>
                );
              })}
              {!isDisabled && (
                <div className='d-flex justify-content-center'>
                  <Button
                    size='sm'
                    variant='secondary'
                    onClick={() => push({ comment: "" })}
                    disabled={isDisabled}
                  >
                    + Komen
                  </Button>
                </div>
              )}
            </React.Fragment>
          )}
        </FieldArray>
      </div>
    </React.Fragment>
  );
};

export default UPENComments;
