import React, { useRef } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";
import CurrencyInput from "react-currency-input-field";
import { useParams } from "react-router-dom";

// icons
import Icon from "@mdi/react";
import { mdiArrowRightBold } from "@mdi/js";

// redux
import { setRevisedCeiling } from "../../../../../redux/slices/jppn/jppnModuleSlice";

const ProjectComponent = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const { projectComponents, projectNameChanges, projectScopes, coordinates } =
    useSelector((state) => ({
      projectComponents: state.jppnReducer.draft.project_components,
      projectNameChanges: state.jppnReducer.draft.project_name,
      projectScopes: state.jppnReducer.draft.project_scopes,
      coordinates: state.jppnReducer.draft.coordinates,
    }));

  return (
    <React.Fragment>
      <div className='mb-3'>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>3.0</span>
          {routeParams?.category == "perubahan-skop" && "Komponen Projek"}
          {routeParams?.category == "kenaikan-siling" && "Komponen Projek"}
          {routeParams?.category === "perubahan-nama-projek" &&
            "Perubahan Nama Projek"}
          {routeParams?.category === "ubah-lokasi" && "Koordinat Projek Baharu"}
        </Form.Label>

        {/* Ceiling Virement */}
        {routeParams?.category === "kenaikan-siling" && (
          <div className='ps-4'>
            <div className='table-responsive'>
              <Table bordered size='sm'>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th className='text-center'>Bil.</th>
                    <th>Komponen Projek</th>
                    <th className='text-center'>Siling Asal (RM)</th>
                    <th className='text-center'>Siling Baharu (RM)</th>
                    <th className='text-center'>Penambahan Siling (RM)</th>
                  </tr>
                </thead>
                <tbody>
                  {projectComponents?.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td className='text-center'>{idx + 1}.</td>
                        <td>{item.cost_description}</td>
                        <td className='text-end'>
                          {numeral(item?.ceiling).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item?.revised_ceiling).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item?.additional_ceiling).format("0,0.00")}
                          {/* <Form.Group>
                            <Form.Control
                              className={`text-end text-truncate`}
                              as={CurrencyInput}
                              placeholder='0.00'
                              decimalsLimit={2}
                              decimalScale={2}
                              value={
                                item?.additional_ceiling
                              }
                              onValueChange={(value) => {
                                const newValues = {
                                  rowId: item.id,
                                  additional_ceiling: value,
                                };
                                dispatch(setRevisedCeiling(newValues));
                              }}
                            />
                          </Form.Group> */}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td />
                    <td>Jumlah</td>
                    <td className='text-end'>
                      {numeral(
                        projectComponents?.reduce(
                          (acc, item) => (acc += parseFloat(item?.ceiling)),
                          0,
                        ),
                      ).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      {numeral(
                        projectComponents?.reduce(
                          (acc, item) =>
                            (acc += parseFloat(item?.revised_ceiling)),
                          0,
                        ),
                      ).format("0,0.00")}
                    </td>
                    <td className='text-end'>
                      {numeral(
                        projectComponents?.reduce(
                          (acc, item) =>
                            (acc += parseFloat(item?.additional_ceiling)),
                          0,
                        ),
                      ).format("0,0.00")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* Project Scope Changes */}
        {routeParams?.category === "perubahan-skop" && (
          <>
            <div
              className='d-flex flex-row'
              style={{ paddingLeft: 24, paddingRight: 2 }}
            >
              <div className='me-3'>3.1</div>
              <div className='table-responsive flex-fill'>
                <Table size='sm' bordered style={{ width: "100%" }}>
                  <thead className='text-uppercase'>
                    <tr>
                      <th className='text-center'>Bil.</th>
                      <th style={{ width: "60%" }}>Komponen Asal</th>
                      <th className='text-center' style={{ width: "35%" }}>
                        Siling (RM)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectScopes?.old?.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className='text-center'>{idx + 1}</td>
                          <td>{item?.cost_description}</td>
                          <td className='text-end pe-2'>
                            {numeral(item.ceiling).format("0,0.00")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div
              className='d-flex flex-row'
              style={{ paddingLeft: 22, paddingRight: 2 }}
            >
              <div className='me-3'>3.2</div>
              <div className='table-responsive flex-fill'>
                <Table size='sm' bordered style={{ width: "100%" }}>
                  <thead className='text-uppercase'>
                    <tr>
                      <th className='text-center'>Bil.</th>
                      <th style={{ width: "60%" }}>Komponen Baharu</th>
                      <th className='text-center' style={{ width: "35%" }}>
                        Siling (RM)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectScopes?.new?.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className='text-center'>{idx + 1}</td>
                          <td>{item?.cost_description?.value}</td>
                          <td className='text-end pe-2'>
                            {numeral(item.ceiling).format("0,0.00")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}

        {/* Project Name Changes */}
        {routeParams?.category === "perubahan-nama-projek" && (
          <div className='ps-4'>
            <div className='table-responsive'>
              <Table bordered size='sm'>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th>Nama Projek Asal</th>
                    <th className='text-center'></th>
                    <th className='text-center'>Nama Projek Baharu </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='py-4'>{projectNameChanges?.old}</td>
                    <td className='align-center text-center'>
                      <Icon path={mdiArrowRightBold} size={1} />
                    </td>
                    <td className='py-4'>{projectNameChanges?.new}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* Location Changes */}
        {routeParams?.category === "ubah-lokasi" && (
          <div className='ps-4'>Sila lihat peta</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProjectComponent;
