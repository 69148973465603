import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

export const getLocationChangesApplications = createAsyncThunk(
  "jppnLocationChangesSlice/getLocationChangesApplications",
  async (props) => {
    try {
      const { source, params } = props;

      const response = await axios.get(`jppn/project-location-changes`, {
        params,
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const getLocationChangesApplication = createAsyncThunk(
  "jppnLocationChangesSlice/getLocationChangesApplication",
  async (params) => {
    try {
      const { id, source } = params;
      const response = await axios.get(`jppn/project-location-changes/${id}`, {
        cancelToken: source.token,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const createLocationChangesApplication = createAsyncThunk(
  "jppnLocationChangesSlice/createLocationChangesApplication",
  async (values) => {
    try {
      const response = await axios.post(
        `jppn/project-location-changes`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const updateLocationChangesApplication = createAsyncThunk(
  "jppnLocationChangesSlice/updateLocationChangesApplication",
  async (values) => {
    try {
      const { id } = values;
      const response = await axios.put(
        `jppn/project-location-changes/${id}`,
        values,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const deleteLocationChangesApplication = createAsyncThunk(
  "jppnLocationChangesSlice/deleteLocationChangesApplication",
  async (id) => {
    try {
      const response = await axios.delete(
        `jppn/project-location-changes/${id}`,
      );

      const data = await response.data;

      return id;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);

export const approveLocationChangesApplication = createAsyncThunk(
  "jppnLocationChangesSlice/approveLocationChangesApplication",
  async (id) => {
    try {
      const response = await axios.put(
        `jppn/project-location-changes/approve/${id}`,
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      } else {
        console.error(error);
      }
    }
  },
);
