import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import numeral from "numeral";

const ExpandOverallExpenditure = ({ show, onHide }) => {
  const [series, setSeries] = useState([]);

  const { overallExpenditure, revisedAllocation } = useSelector((state) => ({
    overallExpenditure:
      state.dashboardReducer.overall.charts.overallExpenditure,
    revisedAllocation: state.dashboardReducer.overall.cards.revisedAllocation,
  }));

  const options = {
    chart: {
      type: "line",
      id: "areachart-2",
      toolbar: { show: false },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.4,
      },
    },
    legend: {
      position: "top",
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      yaxis: [
        {
          y: revisedAllocation / 2,
          borderColor: "#009688",
          label: {
            borderColor: "#009688",
            textAnchor: "start",
            position: "left",
            style: {
              color: "#fff",
              background: "#009688",
            },
            text: "50%",
          },
        },
      ],
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    yaxis: {
      tickAmount: 4,
      show: true,
      title: {
        text: "Perbelanjaan (RM)",
        rotate: -90,
        style: {
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
      },
      min: 0,
      max: revisedAllocation,
      labels: {
        show: true,
        align: "right",
        minWidth: 50,
        maxWidth: 70,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label text-uppercase",
        },
        formatter: (value) => {
          return numeral(value).format("0.0 a");
        },
      },
    },
    xaxis: {
      categories: Array.from({ length: 12 }, (e, i) => {
        return new Date(null, i + 1, null).toLocaleDateString("ms", {
          month: "short",
        });
      }),
    },
    // colors: ["#388e3c", "#303f9f", "#e64a19", "#ffb300"],
    colors: ["#303f9f", "#e64a19", "#ffb300"],
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      formatter: (value, opts) => {
        if (value === null || value === undefined) {
          return "";
        }
        const newValue = `RM ${numeral(value).format("0.0 a")}`;
        return newValue;
      },
      offsetY: -10,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 3,
    },
  };

  useEffect(() => {
    if (isEmpty(overallExpenditure)) return;

    setSeries(overallExpenditure);
  }, [overallExpenditure]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        size='xl'
        centered
        keyboard={false}
        backdrop='static'
      >
        <Modal.Header className='border-0'>
          <h4 className='text-uppercase' style={{ fontWeight: 700 }}>
            Prestasi Perbelanjaan Keseluruhan
          </h4>
        </Modal.Header>
        <Modal.Body className='d-flex'>
          <div className='align-self-center w-100'>
            <div className='chart'>
              <Chart
                options={options}
                series={series}
                type='line'
                height={450}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex border-0'>
          <Button variant='light' onClick={onHide}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ExpandOverallExpenditure;
