import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Form, Table } from "react-bootstrap";
import numeral from "numeral";
import { useParams } from "react-router-dom";

// icons
import Icon from "@mdi/react";
import { mdiArrowRightBold } from "@mdi/js";

import WebMapArcgis from "./maps/WebMapArcgis";

const ProjectComponent = () => {
  const routeParams = useParams();

  const [data, setData] = useState(null);

  const { project } = useSelector((state) => ({
    project: state.jppnReducer.application,
  }));

  useEffect(() => {
    if (routeParams?.category === "perubahan-nama-projek") {
      setData(JSON.parse(project?.project_name));
    }
    if (routeParams?.category === "perubahan-skop") {
      setData(JSON.parse(project?.project_scopes));
    }
    if (routeParams?.category === "ubah-lokasi") {
      setData(JSON.parse(project?.coordinates));
    }
  }, [project]);

  return (
    <React.Fragment>
      <div>
        <Form.Label className='fw-bold text-uppercase'>
          <span className='me-4'>3.0</span>
          {routeParams?.category == "perubahan-skop" && "Komponen Projek"}
          {routeParams?.category == "kenaikan-siling" && "Komponen Projek"}
          {routeParams?.category === "perubahan-nama-projek" &&
            "Perubahan Nama Projek"}
          {routeParams?.category === "ubah-lokasi" && "Lokasi Projek Baharu"}
        </Form.Label>

        {routeParams?.category === "kenaikan-siling" && (
          <div className='ps-4'>
            <div className='table-responsive'>
              <Table size='sm' bordered>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th className='text-center'>Bil.</th>
                    <th>Komponen Projek</th>
                    <th className='text-center'>Siling Asal (RM)</th>
                    <th className='text-center'>Siling Disemak (RM)</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(project?.project_components).map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td className='text-center'>{idx + 1}</td>
                        <td>{item.cost_description}</td>
                        <td className='text-end'>
                          {numeral(item.ceiling).format("0,0.00")}
                        </td>
                        <td className='text-end'>
                          {numeral(item.revised_ceiling).format("0,0.00")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* Project Name Changes */}
        {routeParams?.category === "perubahan-nama-projek" && (
          <div className='ps-4'>
            <div className='table-responsive'>
              <Table bordered size='sm' style={{ width: "100%" }}>
                <thead className='custom-table-head text-uppercase'>
                  <tr>
                    <th>Nama Projek Asal</th>
                    <th className='text-center'></th>
                    <th className='text-center'>Nama Projek Baharu </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='py-4' style={{ width: "45%" }}>
                      {data?.old}
                    </td>
                    <td className='align-center text-center'>
                      <Icon path={mdiArrowRightBold} size={1} />
                    </td>
                    <td className='py-4' style={{ width: "45%" }}>
                      {data?.new}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* Project Scopes Changes */}
        {routeParams?.category === "perubahan-skop" && (
          <>
            <div
              className='d-flex flex-row'
              style={{ paddingLeft: 24, paddingRight: 2 }}
            >
              <div className='me-3'>3.1</div>
              <div className='table-responsive flex-fill'>
                <Table size='sm' bordered style={{ width: "100%" }}>
                  <thead className='text-uppercase'>
                    <tr>
                      <th className='text-center'>Bil.</th>
                      <th style={{ width: "60%" }}>Komponen Asal</th>
                      <th className='text-center' style={{ width: "35%" }}>
                        Siling (RM)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.old?.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className='text-center'>{idx + 1}</td>
                          <td>{item?.cost_description}</td>
                          <td className='text-end pe-2'>
                            {numeral(item.ceiling).format("0,0.00")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div
              className='d-flex flex-row'
              style={{ paddingLeft: 22, paddingRight: 2 }}
            >
              <div className='me-3'>3.2</div>
              <div className='table-responsive flex-fill'>
                <Table size='sm' bordered style={{ width: "100%" }}>
                  <thead className='text-uppercase'>
                    <tr>
                      <th className='text-center'>Bil.</th>
                      <th style={{ width: "60%" }}>Komponen Baharu</th>
                      <th className='text-center' style={{ width: "35%" }}>
                        Siling (RM)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.new?.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td className='text-center'>{idx + 1}</td>
                          <td>{item?.cost_description?.value}</td>
                          <td className='text-end pe-2'>
                            {numeral(item.ceiling).format("0,0.00")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}

        {/* Location Changes */}
        {routeParams?.category === "ubah-lokasi" && (
          <div className='ps-4 py-3'>
            <div className='position-relative mb-3' style={{ height: 520 }}>
              <WebMapArcgis project={project.project} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProjectComponent;
