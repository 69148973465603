import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import SignInLayout from "./layouts/SignInLayout";
import DashboardLayout from "./layouts/Dashboard";
import FirstTimeLoginLayout from "./layouts/FirstTimeLoginLayout";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import UserPasswordReset from "./pages/auth/UserPasswordReset";

// Protected routes
// User Profile
import UserProfile from "./pages/user-profile/MainPage";

// Announcement
import AnnouncementPage from "./pages/announcement/MainPage";
import AnnouncementForm from "./pages/announcement/components/AnnouncementForm";

// Dashboards
import DashboardV2 from "./pages/dashboard-v2/MainPage";

// Sub Dashboard
import UserDashboard from "./pages/dashboards-sub/user/MainPage";

// Application V2
import ApplicationNewProjectV2 from "./pages/application-v2/new-project/MainPage";
import SummaryProjectProfile from "./pages/application-v2/new-project/components/SummaryProjectForm";
import ListOfProject from "./pages/application-v2/projects/MainPage";
import ListingProjectJppn from "./pages/application-v2/jppn/listing-project/MainPage";
import CeilingVirement from "./pages/application-v2/jppn/ceiling-virement/MainPage";
import ScopeChanges from "./pages/application-v2/jppn/scope-changes/MainPage";
import ProjectNameChanges from "./pages/application-v2/jppn/project-name-changes/MainPage";
import LocationChanges from "./pages/application-v2/jppn/location-changes/MainPage";

import ProjectProfileJppnApplication from "./pages/application-v2/jppn/project-profile/MainPage";
import VirementInformation from "./pages/application-v2/jppn/project-profile/components/VirementInformation";
import ViewJppnApplication from "./pages/application-v2/jppn/project-profile/components/pdf-components/ViewPaperWork";
import ViewPaperWorkNewProjectListing from "./pages/application-v2/jppn/project-profile/components/pdf-components/ViewPaperWorkNewProjectListing";

// Certify
import CertifyProject from "./pages/certify/project-profile/MainPage";
import CertifyProjects from "./pages/certify/projects/MainPage";
import CertifyJppnApplication from "./pages/certify/jppn/MainPage";
import CertifyJppnProject from "./pages/certify/jppn-project-profile/MainPage";

// Project list
import AnalysisTable from "./pages/project-list/internal-analysis-table/MainPage";
import ProjectProfile from "./pages/project-list/project-profile/MainPage";
import SummaryProjectList from "./pages/project-list/default/MainPage";
import ApplicationStatusList from "./pages/project-list/projects-application-status/MainPage";
import JppnApplicationStatusList from "./pages/project-list/jppn/MainPage";

// Report
import ProjectProgressReports from "./pages/report/projects/MainPage";
import ProjectProfileReport from "./pages/report/project-profile/MainPage";

// Reporting
import ReportForm from "./pages/report/project-profile/reporting/reporting-form/MainPage";

// RMK Information
import RMKPage from "./pages/rmk-information/rmk/MainPage";
import CeilingSettings from "./pages/rmk-information/ceiling-settings/MainPage";

// Program
import Core from "./pages/rmk-information/core/MainPage";
import StateMainSector from "./pages/rmk-information/state-main-sector/MainPage";

import ProgramList from "./pages/rmk-information/programs/MainPage";
import MainProjects from "./pages/rmk-information/main-project/MainPage";

// Admin
import Users from "./pages/admin/users/MainPage";
import UserForm from "./pages/admin/users/UserForm";
import ActivityLog from "./pages/admin/activity-log/MainPage";
import LoginRecord from "./pages/admin/login-record/MainPage";

// Maintenance
// Maklumat Negeri
import District from "./pages/maintenance/sabah-information/district/MainPage";
import Parliament from "./pages/maintenance/sabah-information/parliament/MainPage";
import StateLegislativeAssembly from "./pages/maintenance/sabah-information/state-legislative-assembly/MainPage";
import SustainableDevelopmentGoal from "./pages/maintenance/sustainable-development-goal/MainPage";
// Kementerian dan Jabatan
import MinistryAdmin from "./pages/maintenance/government-structure/ministry/MainPage";
import DepartmentAdmin from "./pages/maintenance/government-structure/department/MainPage";
import BranchAdmin from "./pages/maintenance/government-structure/branch/MainPage";
import ExecutingAgency from "./pages/maintenance/government-structure/executing-agency/MainPage";

//Kumpulan Sasar
import TargetGroup from "./pages/maintenance/target-group/MainPage";
//Skop Projek
import ScopeProject from "./pages/maintenance/project-scope/MainPage";
//Upen Sections
import UpenSection from "./pages/maintenance/upen-section/MainPage";
// Credit Score
import CreditScoreCriteria from "./pages/maintenance/credit-score/MainPage";
import UtilitiesPage from "./pages/maintenance/utilities/MainPage";

// Others
import ProjectCodes from "./pages/admin/project-code/MainPage";
import DataEkonomi from "./components/common/DataEkonomi";

// User Management
import UserRegistrationList from "./pages/admin/user-management/user-registration/MainPage";
import UserRegistrationForm from "./pages/admin/user-management/user-registration/components/RegistrationForm";
import UserAcknowledge from "./pages/admin/user-management/user-acknowledge/MainPage";
import SuccessVerified from "./pages/admin/user-management/user-acknowledge/SuccessPage";

// Group Roles
import GroupRoles from "./pages/admin/group-roles/MainPage";

// Micro Project
import MicroProjectDashboard from "./pages/micro-project/dashboard/MainPage";
import MicroProjectApplication from "./pages/micro-project/application-form/MainPage";
import MicroProjectApplicationForm from "./pages/micro-project/application-form/ApplicationForm";
import MicroProjectList from "./pages/micro-project/projects/MainPage";
import MicroProjectApproval from "./pages/micro-project/projects-approval/MainPage";
import MicroProjectInternalAnalysisTable from "./pages/micro-project/internal-analysis-table/MainPage";

// Change Password First time loign
import FirstTimeLogin from "./pages/auth/FirstTimeLogin";

// Projek Mikro Admin
import MicroProjectUsers from "./pages/admin-micro-project/users/MainPage";
import MicroProjectUserForm from "./pages/admin-micro-project/user-form/MainPage";
import MicroProjectSection from "./pages/admin-micro-project/section/MainPage";

const routes = [
  {
    path: "auth",
    element: <SignInLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/pengumuman",
        element: <AnnouncementPage />,
      },
      {
        path: "/pengumuman/:type",
        element: <AnnouncementForm />,
      },
      {
        path: "/pengumuman/:type/:uuid",
        element: <AnnouncementForm />,
      },
      {
        path: "dashboard",
        element: <DashboardV2 />,
      },
      {
        path: "data-ekonomi",
        element: <DataEkonomi />,
      },
    ],
  },
  {
    path: "permohonan",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "projek/:projectId/:applicationCategory/:type",
        element: <ApplicationNewProjectV2 />,
      },
      {
        path: "projek/:applicationCategory/:type",
        element: <ApplicationNewProjectV2 />,
      },
      {
        path: "senarai-projek/:applicationType",
        element: <ListOfProject />,
      },
      {
        path: "butiran-projek/:projectId/ringkasan-profil-projek",
        element: <SummaryProjectProfile />,
      },
      {
        path: "butiran-projek/:projectId",
        element: <ApplicationNewProjectV2 />,
      },
      {
        path: "butiran-projek/:applicationType/:projectId",
        element: <ApplicationNewProjectV2 />,
      },
      {
        path: "jppn/:category/:id",
        element: <ProjectProfileJppnApplication />,
      },
      {
        path: "jppn/:category/:id",
        element: <VirementInformation />,
      },
      {
        path: "jppn/:category/:id/:type",
        element: <ProjectProfileJppnApplication />,
      },
      {
        path: "jppn/:category/:id/semak",
        element: <ViewJppnApplication />,
      },
      {
        path: "jppn/penyenaraian-projek-baharu/:id/semak",
        element: <ViewPaperWorkNewProjectListing />,
      },
    ],
  },
  {
    path: "perakuan",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "senarai-projek",
        element: <CertifyProjects />,
      },
      {
        path: ":stages/:projectId",
        element: <CertifyProject />,
      },
      {
        path: "jppn",
        element: <CertifyJppnApplication />,
      },
      {
        path: "jppn",
        element: <CertifyJppnApplication />,
      },
      {
        path: "jppn/:category/:id",
        element: <CertifyJppnProject />,
      },
    ],
  },
  {
    path: "senarai-projek",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SummaryProjectList />,
      },
      {
        path: "jadual-analisis-dalaman",
        element: <AnalysisTable />,
      },
      {
        path: "status-permohonan",
        element: <ApplicationStatusList />,
      },
      {
        path: "jppn/penyenaraian-projek",
        element: <ListingProjectJppn />,
      },
      {
        path: "jppn/kenaikan-siling",
        element: <CeilingVirement />,
      },
      {
        path: "jppn/perubahan-skop",
        element: <ScopeChanges />,
      },
      {
        path: "jppn/perubahan-nama-projek",
        element: <ProjectNameChanges />,
      },
      {
        path: "jppn/ubah-lokasi",
        element: <LocationChanges />,
      },
      {
        path: "jppn/status-permohonan",
        element: <JppnApplicationStatusList />,
      },
    ],
  },
  {
    path: "profil-projek",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ":projectId",
        element: <ProjectProfile />,
      },
    ],
  },
  {
    path: "laporan",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "senarai-projek",
        element: <ProjectProgressReports />,
      },
      {
        path: "kemaskini-kemajuan-projek/:projectId",
        element: <ProjectProfileReport />,
      },
      {
        path: "kemaskini-kemajuan-projek/:projectId/laporan/:reportId",
        element: <ReportForm />,
      },
    ],
  },
  {
    path: "maklumat-rmk",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "rancangan-malaysia",
        element: <RMKPage />,
      },
      {
        path: "tetapan-siling",
        element: <CeilingSettings />,
      },
      {
        path: "sektor-utama-negeri",
        element: <StateMainSector />,
      },
      {
        path: "teras",
        element: <Core />,
      },
      {
        path: "senarai-program-rmk",
        element: <ProgramList />,
      },
      {
        path: "senarai-projek-utama",
        element: <MainProjects />,
      },
    ],
  },
  {
    path: "penyelenggaraan",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "skop-projek",
        element: <ScopeProject />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "pengguna/:type/:id",
        element: <UserForm />,
      },
      {
        path: "pengguna/:type",
        element: <UserForm />,
      },
      {
        path: "senarai-pengguna",
        element: <Users />,
      },
      {
        path: "maklumat-negeri/daerah",
        element: <District />,
      },
      {
        path: "maklumat-negeri/parlimen",
        element: <Parliament />,
      },
      {
        path: "maklumat-negeri/dewan-undangan-negeri",
        element: <StateLegislativeAssembly />,
      },
      {
        path: "struktur-kerajaan/:shortForm/:ministryCode/jabatan-dan-agensi",
        element: <DepartmentAdmin />,
      },
      {
        path: "struktur-kerajaan/kementerian",
        element: <MinistryAdmin />,
      },
      {
        path: "struktur-kerajaan/jabatan-dan-agensi",
        element: <DepartmentAdmin />,
      },
      {
        path: "struktur-kerajaan/jabatan/cawangan",
        element: <BranchAdmin />,
      },
      {
        path: "struktur-kerajaan/agensi-pelaksana",
        element: <ExecutingAgency />,
      },
      {
        path: "penyelenggaraan/kumpulan-sasar",
        element: <TargetGroup />,
      },
      {
        path: "penyelenggaraan/skop-projek",
        element: <ScopeProject />,
      },
      {
        path: "penyelenggaraan/seksyen-upen",
        element: <UpenSection />,
      },
      {
        path: "penyelenggaraan/maklumat-pembangunan-mampan",
        element: <SustainableDevelopmentGoal />,
      },
      {
        path: "penyelenggaraan/skor-kredit-kriteria",
        element: <CreditScoreCriteria />,
      },
      {
        path: "penyelenggaraan/senarai-kod-projek",
        element: <ProjectCodes />,
      },
      {
        path: "penyelenggaraan/utiliti",
        element: <UtilitiesPage />,
      },
      {
        path: "log-aktiviti",
        element: <ActivityLog />,
      },
      {
        path: "rekod-log-masuk",
        element: <LoginRecord />,
      },
      {
        path: "senarai-peranan",
        element: <GroupRoles />,
      },
    ],
  },
  {
    path: "pengurusan-pengguna",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "senarai-pengguna-berdaftar",
        element: <UserRegistrationList />,
      },
      {
        path: "daftar-pengguna/:type",
        element: <UserRegistrationForm />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profil/:id",
        element: <UserProfile />,
      },
    ],
  },
  {
    path: "404",
    element: <Page404 />,
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "ubah-kata-laluan",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "pengesahan-pengguna/:code",
    element: <UserAcknowledge />,
  },
  {
    path: "pengesahan-pengguna/berjaya",
    element: <SuccessVerified />,
  },
  {
    path: "lupa-kata-laluan",
    element: <ResetPassword />,
  },
  {
    path: "set-semula-kata-laluan/:code",
    element: <UserPasswordReset />,
  },
  {
    path: "projek-mikro",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        element: <MicroProjectDashboard />,
      },
      {
        path: "daftar-projek/:ministry/:type/:id",
        element: <MicroProjectApplicationForm />,
      },
      {
        path: "daftar-projek",
        element: <MicroProjectApplication />,
      },
      {
        path: "senarai-projek",
        element: <MicroProjectList />,
      },
      {
        path: "status-senarai-projek",
        element: <MicroProjectApproval />,
      },
      {
        path: "jadual-analisis-dalaman",
        element: <MicroProjectInternalAnalysisTable />,
      },
      {
        path: "admin/senarai-pengguna",
        element: <MicroProjectUsers />,
      },
      {
        path: "admin/pengguna/:type/:id",
        element: <MicroProjectUserForm />,
      },
      {
        path: "admin/pengguna/:type",
        element: <MicroProjectUserForm />,
      },
      {
        path: "admin/seksyen",
        element: <MicroProjectSection />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <FirstTimeLoginLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "tukar-kata-laluan",
        element: <FirstTimeLogin />,
      },
    ],
  },
];

export default routes;
