import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

// components
import UploadDocument from "./UploadDocument";

// redux
import { updateMicroProject } from "../../../../redux/slices/micro-project/microProjectSlice";
import ActionButton from "./ActionButton";
import ApprovalButton from "./ApprovalButton";
import ConfirmSend from "./ConfirmSend";
import ReturnButton from "./ReturnButton";
import ReturnApplicationForm from "./ReturnApplicationForm";
import RejectApplicationForm from "./RejectApplicationForm";
import ReturnApplication from "./ReturnApplication";
import RejectApplication from "./RejectApplication";

const ProjectInformation = ({ sendNotification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();

  const { id, ministry, type } = routeParams;

  const [isDraft, setIsDraft] = useState(true);
  const [dunOptions, setDunOptions] = useState([]);
  const [mainProjectValue, setMainProjectValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [hideSendButton, setHideSendButton] = useState(false);
  const [hideApproveButton, setHideApproveButton] = useState(false);
  const [hideReturnButton, setHideReturnButton] = useState(false);
  const [showConfirmApplication, setShowConfirmApplication] = useState(false);
  const [showReturnApplicationForm, setShowReturnApplicationForm] =
    useState(false);
  const [showRejectApplicationForm, setShowRejectApplicationForm] =
    useState(false);
  const [showReturnApplicationButton, setShowReturnApplicationButton] =
    useState(false);
  const [applicationAction, setApplicationAction] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [showActionButton, setShowActionButton] = useState(false);

  const {
    user,
    mainProjectOptions,
    districtOptions,
    parliamentOptions,
    project,
    btnLoading,
  } = useSelector((state) => ({
    mainProjectOptions: state.utilsReducer.dropdownItems.mainProjects,
    districtOptions: state.utilsReducer.dropdownItems.districtItems,
    parliamentOptions: state.utilsReducer.dropdownItems.parliamentItems,
    project: state.microProjectReducer.microProject,
    btnLoading: state.microProjectReducer.btnLoading,
    user: state.userReducer.user,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      main_project: project?.main_project || null,
      district: project?.district || null,
      parliament: project?.parliament || null,
      state_legislative_assembly: project?.state_legislative_assembly || null,
      name: project?.name || "",
      location: project?.location || "",
      site_status: project?.site_status || "",
      justifications: project?.justifications || "",
      cost_approved: project?.cost_approved || "",
      cost_requirement: project?.cost_requirement || "",
      remarks: project?.remarks || "",
      reference_no: project?.reference_no || "",
      district_office_reference_no: project?.district_office_reference_no || "",
      approved_application_remarks: project?.approved_application_remarks
        ? JSON.parse(project?.approved_application_remarks)
        : "",
    },
    validationSchema: Yup.object().shape({
      main_project: Yup.object()
        .shape()
        .required("Sila pilih projek utama")
        .nullable(),
      name: Yup.string().required("Sila masukkan nama projek"),
      district_office_reference_no: Yup.string().required(
        "Sila masukkan no. rujukan pejabat",
      ),
      justifications: Yup.string().required(
        "Sila masukkan justifikasi keperluan projek",
      ),
      location: Yup.string().required("Sila masukkan lokasi projek"),
      site_status: Yup.string().required("Sila masukkan status tapak"),
      cost_requirement: Yup.string().required(
        "Sila masukkan peruntukan dipohon",
      ),
    }),
    onSubmit: async (values) => {
      let mainProjectId = null;

      if (ministry === "11") {
        mainProjectId = mainProjectValue?.id;
      } else {
        mainProjectId = values.main_project?.id;
      }

      if (isDraft) {
        const newValues = {
          id: id,
          main_project_id: mainProjectId,
          district_id: values.district.id || null,
          state_legislative_assembly_id:
            values.state_legislative_assembly?.id || null,
          parliament_id: values.parliament?.id || null,
          name: values.name,
          location: values.location,
          site_status: values.site_status,
          justifications: values.justifications,
          cost_requirement: values.cost_requirement,
          cost_approved: values.cost_approved,
          reference_no: values.reference_no,
          district_office_reference_no: values.district_office_reference_no,
          remarks: values.remarks,
          application_status: applicationAction?.status,
          application_stages: applicationAction?.stages,
        };
        await dispatch(updateMicroProject(newValues));
        sendNotification();
      } else {
        const newValues = {
          id: id,
          main_project_id: mainProjectId,
          district_id: values.district.id || null,
          state_legislative_assembly_id:
            values.state_legislative_assembly?.id || null,
          parliament_id: values.parliament?.id || null,
          name: values.name,
          location: values.location,
          site_status: values.site_status,
          justifications: values.justifications,
          cost_requirement: values.cost_requirement,
          cost_approved: values.cost_approved,
          reference_no: values.reference_no,
          district_office_reference_no: values.district_office_reference_no,
          remarks: values.remarks,
          application_status: applicationAction?.status,
          application_stages: applicationAction?.stages,
          approved_application_remarks: values.approved_application_remarks,
        };

        await dispatch(updateMicroProject(newValues));
        if (type === "kelulusan") {
          navigate(`/projek-mikro/status-senarai-projek`);
        } else {
          navigate(`/projek-mikro/senarai-projek`);
        }
      }

      setApplicationAction(null);
      setActionType(null);
    },
  });

  const handleSelectMainProject = (value) => {
    formik.setFieldValue("main_project", value);
  };

  const handleSelectDistrict = (value) => {
    formik.setFieldValue("district", value);
  };

  const handleSelectDun = (value) => {
    formik.setFieldValue("state_legislative_assembly", value);
  };

  const handleSelectParliament = (value) => {
    formik.setFieldValue("parliament", value);
  };

  const handleActionOne = async (e, obj) => {
    if (obj.status === 6) {
      handleShowReturnApplicationForm(true);
    } else {
      e.preventDefault();
      setIsDraft(true);
      setApplicationAction(obj);
      formik.handleSubmit();
      return false;
    }
  };

  const handleSendApplication = (obj) => {
    setShowConfirmApplication(true);
    setApplicationAction(obj);
    setActionType("approve");
  };

  const handleConfirmSend = (e) => {
    e.preventDefault();

    setIsDraft(false);
    formik.handleSubmit();
    return false;
  };

  const handleShowReturnApplicationForm = () => {
    setShowReturnApplicationForm(true);
  };

  const handleShowRejectApplicationForm = () => {
    setShowRejectApplicationForm(true);
  };

  useEffect(() => {
    const options = parliamentOptions
      ?.flatMap((item) => item.state_legislative_assemblies)
      ?.map((item) => ({ ...item, label: item.name, value: item.name }));

    setDunOptions(options);
  }, [parliamentOptions]);

  useEffect(() => {
    const mainProject = mainProjectOptions?.find(
      (item) => item?.code === "0001",
    );

    setMainProjectValue(mainProject);
  }, [ministry, mainProjectOptions]);

  useEffect(() => {
    if (isEmpty(project)) return false;

    const status = project?.application_status;

    if (status === 1 || status === 6) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    const hasDistrictAccess = !isEmpty(user?.user_access_to_district);

    if (hasDistrictAccess) {
      const isDraft = type === "kemas-kini" && status === 1;
      const isReturn = type === "kemas-kini" && status === 6;
      const isKelulusan = type === "kelulusan" && status === 2;
      setShowActionButton(isDraft || isReturn || isKelulusan);
    }

    if (!hasDistrictAccess) {
      const isRelevantStatus = status === 3 || status === 4 || status === 5;
      setShowActionButton(isRelevantStatus);
    }

    const district = project?.district;
    handleSelectDistrict(district);
  }, [project]);

  useEffect(() => {
    if (isEmpty(user?.user_access_to_district)) return false;

    handleSelectDistrict(user?.user_access_to_district);
  }, [user]);

  return (
    <React.Fragment>
      <ConfirmSend
        show={showConfirmApplication}
        hide={() => {
          setShowConfirmApplication(false);
          setActionType(null);
          setApplicationAction(null);
        }}
        confirm={(e) => handleConfirmSend(e)}
        formik={formik}
      />

      <ReturnApplicationForm
        show={showReturnApplicationForm}
        onHide={() => {
          setShowReturnApplicationForm(false);
        }}
      />

      <RejectApplicationForm
        show={showRejectApplicationForm}
        onHide={() => {
          setShowRejectApplicationForm(false);
        }}
      />

      <Card className='flex-fill w-100'>
        <Form autoComplete='off'>
          <Card.Body>
            <h4>Maklumat Projek</h4>

            <Form.Group className='mb-3'>
              <Form.Label>Projek Utama</Form.Label>
              {ministry === "11" && (
                <Form.Control
                  name='main_project'
                  type='text'
                  defaultValue={mainProjectValue?.value}
                  disabled
                />
              )}

              {ministry === "19" && (
                <>
                  <Select
                    name='main_project'
                    // className='react-select-container'
                    // classNamePrefix='react-select'
                    isSearchable
                    isClearable
                    placeholder='Pilih Projek Utama'
                    options={mainProjectOptions}
                    value={formik.values?.main_project}
                    onChange={(value) => handleSelectMainProject(value)}
                    noOptionsMessage={() => "Tiada pilihan"}
                    isDisabled={isDisabled}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#ced4da",
                        backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                      }),
                    }}
                  />
                  {formik.touched.main_project &&
                    formik.errors.main_project && (
                      <div className='invalid-feedback d-block'>
                        {formik.errors.main_project}
                      </div>
                    )}
                </>
              )}
              <Form.Control.Feedback type='invalid'>
                {formik.errors.main_project}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Daerah</Form.Label>

              <Select
                name='district'
                // className='react-select-container'
                // classNamePrefix='react-select'
                isSearchable
                isClearable
                placeholder='Pilih Daerah'
                options={districtOptions}
                value={formik.values?.district}
                onChange={(value) => handleSelectDistrict(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#ced4da",
                    backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                  }),
                }}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Parlimen</Form.Label>
              <Select
                name='parliament'
                // className='react-select-container'
                // classNamePrefix='react-select'
                isSearchable
                isClearable
                placeholder='Pilih Parlimen'
                options={parliamentOptions}
                value={formik.values?.parliament}
                onChange={(value) => handleSelectParliament(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled={isDisabled}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#ced4da",
                    backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                  }),
                }}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Dewan Undangan Negeri (DUN)</Form.Label>
              <Select
                name='state_legislative_assembly'
                // className='react-select-container'
                // classNamePrefix='react-select'
                isSearchable
                isClearable
                placeholder='Pilih DUN'
                options={dunOptions}
                value={formik.values?.state_legislative_assembly}
                onChange={(value) => handleSelectDun(value)}
                noOptionsMessage={() => "Tiada pilihan"}
                isDisabled={isDisabled}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "#ced4da",
                    backgroundColor: state.isDisabled ? "#e2e8ee" : "#FFF",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change text color to black when disabled
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? "rgb(84, 84, 84)" : "#495057", // Change placeholder color to black when disabled
                  }),
                }}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>No. Rujukan Sistem</Form.Label>
                  <Form.Control
                    type='text'
                    name='reference_no'
                    value={formik?.values?.reference_no}
                    onChange={formik.handleChange}
                    onBlur={formik?.handleBlur}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>No. Rujukan Fail (Pejabat Daerah)</Form.Label>
                  <Form.Control
                    type='text'
                    name='district_office_reference_no'
                    value={formik?.values?.district_office_reference_no}
                    onChange={formik.handleChange}
                    onBlur={formik?.handleBlur}
                    disabled={isDisabled}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className='mb-3'>
              <Form.Label>Nama Projek</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='name'
                value={formik?.values?.name}
                onChange={formik.handleChange}
                onBlur={formik?.handleBlur}
                disabled={isDisabled}
                isInvalid={
                  formik.touched.name && formik.errors.name ? true : false
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Justifikasi Keperluan Projek</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='justifications'
                value={formik?.values?.justifications}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isDisabled}
                isInvalid={
                  formik.touched.justifications && formik.errors.justifications
                    ? true
                    : false
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.justifications}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Lokasi / Alamat Projek</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='location'
                value={formik.values?.location}
                onChange={formik?.handleChange}
                onBlur={formik.handleBlur}
                disabled={isDisabled}
                isInvalid={
                  formik.touched.location && formik.errors.location
                    ? true
                    : false
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.location}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Status Tapak Projek</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='site_status'
                value={formik?.values?.site_status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isDisabled}
                isInvalid={
                  formik.touched.site_status && formik.errors.site_status
                    ? true
                    : false
                }
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.site_status}
              </Form.Control.Feedback>
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>Peruntukan Projek Yang Dipohon (RM)</Form.Label>
                  <Form.Control
                    placeholder='0.00'
                    as={CurrencyInput}
                    min={0}
                    autoComplete='off'
                    decimalsLimit={2}
                    decimalScale={2}
                    name='cost_requirement'
                    value={formik.values?.cost_requirement}
                    onValueChange={(value) => {
                      formik.setFieldValue("cost_requirement", value);
                    }}
                    onBlur={formik.handleBlur}
                    disabled={isDisabled}
                    isInvalid={
                      formik.touched.cost_requirement &&
                      formik.errors.cost_requirement
                        ? true
                        : false
                    }
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.cost_requirement}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                {!isEmpty(user?.user_access_to_ministries) && (
                  <>
                    <Form.Group className='mb-3'>
                      <Form.Label>
                        Peruntukan Projek Yang Diluluskan (RM)
                      </Form.Label>
                      <Form.Control
                        placeholder='0.00'
                        as={CurrencyInput}
                        min={0}
                        autoComplete='off'
                        decimalsLimit={2}
                        decimalScale={2}
                        name='cost_approved'
                        value={formik.values?.cost_approved}
                        onValueChange={(value) => {
                          formik.setFieldValue("cost_approved", value);
                        }}
                        onBlur={formik.handleBlur}
                        disabled={project?.application_status === 5}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Catatan Kelulusan</Form.Label>
                      <Form.Control
                        name='approved_application_remarks'
                        type='text'
                        as='textarea'
                        style={{ height: 80, maxHeight: 120 }}
                        value={formik.values?.approved_application_remarks}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={project?.application_status === 5}
                      />
                    </Form.Group>
                  </>
                )}
              </Col>
            </Row>

            <Form.Group className='mb-3'>
              <Form.Label>Catatan</Form.Label>
              <Form.Control
                as='textarea'
                style={{ minHeight: 90, maxHeight: 150 }}
                name='remarks'
                value={formik?.values?.remarks}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isDisabled}
              />
            </Form.Group>

            <UploadDocument isDisabled={isDisabled} />
            {project?.application_status === 6 && <ReturnApplication />}
            {project?.application_status === 7 && <RejectApplication />}
          </Card.Body>

          {/* <div>
              {showReturnApplicationButton && (
                <ReturnButton
                  returnApplication={handleShowReturnApplicationForm}
                />
              )}
            </div>
            <div className='d-flex gap-3'>
              {type === "kemas-kini" && hideSendButton && (
                <ActionButton
                  handleActionOne={handleActionOne}
                  sendApplication={handleSendApplication}
                />
              )}
              {type === "kelulusan" && (
                <ApprovalButton
                  user={user}
                  hideSendButton={hideSendButton}
                  hideApproveButton={hideApproveButton}
                />
              )}
            </div> */}

          {showActionButton && (
            <Card.Footer className='d-flex justify-content-between gap-3'>
              <div>
                {project?.action_button?.delete && (
                  <Button
                    variant='danger'
                    onClick={handleShowRejectApplicationForm}
                  >
                    {project?.action_button?.delete?.text}
                  </Button>
                )}
              </div>
              <div className='d-flex gap-3'>
                {project?.action_button?.action_1 && (
                  <Button
                    variant='secondary'
                    disabled={btnLoading}
                    onClick={(e) =>
                      handleActionOne(e, project?.action_button?.action_1)
                    }
                  >
                    {project?.action_button?.action_1?.text}
                  </Button>
                )}
                {project?.action_button?.action_2 && (
                  <Button
                    variant='success'
                    disabled={btnLoading}
                    onClick={() =>
                      handleSendApplication(project?.action_button?.action_2)
                    }
                  >
                    {project?.action_button?.action_2?.text}
                  </Button>
                )}
              </div>
            </Card.Footer>
          )}
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default ProjectInformation;
